import styled from '@emotion/styled';

import { COLORS } from '../../styles/colors';
import { FOOTER_HEIGHT } from '../../styles/theme';

export const Container = styled.div({
    width: '100%',
    height: FOOTER_HEIGHT,

    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',

    padding: '0 24px',
    color: COLORS.white,
    backgroundColor: COLORS.darkBlue,
});
