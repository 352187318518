import { createTheme, useMediaQuery } from '@mui/material';

import { COLORS } from './colors';

export const TOP_BAR_HEIGHT = 90;
export const FOOTER_HEIGHT = 50;

export enum Z_INDEX_VALUES {
    APP_BAR = 1200,
    DRAWER = 1100,
}

const theme = createTheme({
    palette: {
        primary: {
            main: COLORS.darkBlue,
        },
    },
    typography: {
        fontFamily: 'Khand',
        body1: {
            fontSize: 18,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Khand';
                    font-weight: 400;
                    font-display: swap;
                    font-style: normal;
                    font-named-instance: 'Regular';
                    src: url(/fonts/Khand-Regular.woff2) format("woff2");
                  }
                `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    textTransform: 'none',
                },
                contained: {
                    '@media (hover: hover)': {
                        '&:hover': {
                            color: COLORS.orange,
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: COLORS.white,
                    '@media (hover: hover)': {
                        '&:hover': {
                            color: COLORS.orange,
                        },
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    padding: '20px 0',
                    backgroundColor: COLORS.darkBlue,
                    minWidth: '250px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                    '& > ul': {
                        width: '100%',
                        '& > a': {
                            display: 'flex',
                            color: COLORS.white,
                            textAlign: 'center',
                            padding: 0,
                            height: 60,
                            width: '100%',
                            '@media (hover: hover)': {
                                '&:hover': {
                                    color: COLORS.orange,
                                },
                            },
                        },
                    },
                },
            },
        },
    },
});

export function isMobile() {
    return useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
}

export default theme;
