import React from 'react';

import TopLayout from './components/TopLayout/TopLayout';
import theme from './styles/theme';

type WrapWithProviderProps = {
    element: React.ReactNode;
};

const wrapWithLayout: React.FC<WrapWithProviderProps> = ({ element }) => {
    return <TopLayout theme={theme}>{element}</TopLayout>;
};

export default wrapWithLayout;
