export const DRAWER_ROUTES = [
    {
        text: 'Produse',
        link: '/produse',
    },
    {
        text: 'Servicii',
        link: '/servicii',
    },
    {
        text: 'Despre noi',
        link: '/despre',
    },
    {
        text: 'Contact',
        link: '/contact',
    },
];
