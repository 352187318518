import React from 'react';
import { Provider } from 'react-redux';

import { store } from './store';

type WrapWithProviderProps = {
    element: React.ReactNode;
};

const wrapWithProvider: React.FC<WrapWithProviderProps> = ({ element }) => {
    return <Provider store={store}>{element}</Provider>;
};

export default wrapWithProvider;
