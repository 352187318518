import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DrawerState = {
    isOpen: boolean;
};

const INITIAL_STATE: DrawerState = {
    isOpen: false,
};

export const drawerSlice = createSlice({
    name: 'drawerSlice',
    initialState: INITIAL_STATE,
    reducers: {
        setToggled: (state: DrawerState, action: PayloadAction<boolean>) => {
            return { ...state, isOpen: action.payload };
        },
    },
});

export const { setToggled } = drawerSlice.actions;
