import { Typography } from '@mui/material';
import React from 'react';

import { Container } from './FooterStyles';

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();
    return (
        <Container>
            <Typography variant="body2">Copyright &copy; {currentYear} Artemis Team | artemisteam.ro</Typography>
        </Container>
    );
};

export default Footer;
