import { GatsbyLinkProps, Link as GLink } from 'gatsby';
import React from 'react';

export type CustomGatsbyLinkProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>;

const Link: React.FC<CustomGatsbyLinkProps> = ({ className, children, ...props }) => (
    <GLink className={className} {...props}>
        {children}
    </GLink>
);

export default Link;
