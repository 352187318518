// eslint-disable-next-line import/no-unassigned-import
import './src/styles/global.scss';

import wrapWithLayout from './src/wrap-with-layout';
import wrapWithProvider from './src/wrap-with-provider';
let timeout;
// Workaround to fix bug with scroll position when navigating between pages
export const shouldUpdateScroll = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        window.scrollTo(0, 0);
        const body = document.getElementsByTagName('body')[0];
        body.scrollTop = 0;
    }, 0);
    return false;
};

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithLayout;
