import styled from '@emotion/styled';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setToggled } from '../../store/drawerSlice';
import { COLORS } from '../../styles/colors';
import { isMobile, TOP_BAR_HEIGHT, Z_INDEX_VALUES } from '../../styles/theme';
import Link, { CustomGatsbyLinkProps } from '../CustomLink/CustomLink';
import FacebookIconButton from '../FacebookIconButton';
import YoutubeIconButton from '../YoutubeIconButton';
import HideOnScroll from './HideOnScroll';

const Container = styled.div({
    width: '100%',
    position: 'sticky',
    top: 0,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '0 24px',
    color: COLORS.white,
    backgroundColor: COLORS.darkBlue,

    zIndex: Z_INDEX_VALUES.APP_BAR,

    '& > div': {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    '& > div:first-of-type > *': {
        marginRight: 'auto',
    },
    '&  > div:last-of-type > *': {
        marginLeft: 'auto',
    },
});

const Menu = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 50,
    fontSize: 18,
});

const StyledLink = styled((props: CustomGatsbyLinkProps) => <Link activeStyle={activeStyle} {...props} />)({
    borderBottom: `2px solid transparent`,
});

const activeStyle = {
    color: COLORS.orange,
    borderBottom: `2px solid ${COLORS.orange}`,
};

const TopNavbar: React.FC = () => {
    const shouldShowDrawerIcon = isMobile() ?? true;
    const dispatch = useDispatch();

    return (
        <HideOnScroll>
            <Container style={{ height: TOP_BAR_HEIGHT }}>
                {shouldShowDrawerIcon ? (
                    <>
                        <div>
                            <IconButton aria-label="menu" onClick={() => dispatch(setToggled(true))}>
                                <MenuIcon></MenuIcon>
                            </IconButton>
                        </div>
                        <div>
                            <StyledLink to="/" activeStyle={{}} style={{ width: 80, height: 80 }}>
                                <img src="/images/logo.webp" alt="logo" style={{ width: 80, height: 80 }} />
                            </StyledLink>
                        </div>
                    </>
                ) : (
                    <>
                        <div></div>
                        <Menu>
                            <StyledLink to="/">Acasa</StyledLink>
                            <StyledLink to="/servicii">Servicii</StyledLink>
                            <StyledLink to="/" activeStyle={{}} style={{ width: 80, height: 80 }}>
                                <img src="/images/logo.webp" alt="logo" style={{ width: 80, height: 80 }} />
                            </StyledLink>
                            <StyledLink to="/despre">Despre</StyledLink>
                            <StyledLink to="/contact">Contact</StyledLink>
                        </Menu>
                    </>
                )}
                <div>
                    <div>
                        <FacebookIconButton />
                        <YoutubeIconButton />
                    </div>
                </div>
            </Container>
        </HideOnScroll>
    );
};

export default TopNavbar;
