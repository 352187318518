import FacebookIcon from '@mui/icons-material/Facebook';
import { IconButton } from '@mui/material';
import React from 'react';

const FacebookIconButton: React.FC = () => {
    const openLink = () => {
        window.open('https://www.facebook.com/artemis.tactics/', '_blank', 'noopener,noreferrer');
    };

    return (
        <IconButton aria-label="facebook" onClick={openLink}>
            <FacebookIcon />
        </IconButton>
    );
};

export default FacebookIconButton;
