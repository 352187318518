import YouTubeIcon from '@mui/icons-material/YouTube';
import { IconButton } from '@mui/material';
import React from 'react';

const YoutubeIconButton: React.FC = () => {
    const openLink = () => {
        window.open('https://www.youtube.com/@artemis.tactics', '_blank', 'noopener,noreferrer');
    };

    return (
        <IconButton aria-label="youtube" onClick={openLink}>
            <YouTubeIcon />
        </IconButton>
    );
};

export default YoutubeIconButton;
