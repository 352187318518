import { Slide, useScrollTrigger } from '@mui/material';
import React from 'react';

interface HideOnScrollProps {
    children: React.ReactElement;
}

const HideOnScroll: React.FC<HideOnScrollProps> = (props) => {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger} timeout={400}>
            {children}
        </Slide>
    );
};
export default HideOnScroll;
