import { CacheProvider } from '@emotion/react';
import styled from '@emotion/styled';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

import { makeMuiCache } from '../../styles/cache';
import { FOOTER_HEIGHT, TOP_BAR_HEIGHT } from '../../styles/theme';
import CustomDrawer from '../Drawer/Drawer';
import Footer from '../Footer/Footer';
import TopNavbar from '../TopNavbar/TopNavbar';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const muiCache = makeMuiCache();
type TopLayoutProps = {
    children: React.ReactNode;
    theme: Theme;
};

const Main = styled.main({
    width: '100%',
    minHeight: `calc(100vh - ${TOP_BAR_HEIGHT + FOOTER_HEIGHT}px)`,
    position: 'relative',
});

const TopLayout: React.FC<TopLayoutProps> = ({ children, theme }) => {
    return (
        <>
            <CacheProvider
                value={muiCache} // <-- use the new cache here
            >
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <CustomDrawer />
                    <TopNavbar />
                    <Main>{children}</Main>
                    <Footer />
                </ThemeProvider>
            </CacheProvider>
        </>
    );
};

export default TopLayout;
