import { Divider, List, ListItem } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { Link } from 'gatsby';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store';
import { setToggled } from '../../store/drawerSlice';
import { COLORS } from '../../styles/colors';
import { DRAWER_ROUTES } from './DrawerData';

const CustomDrawer: React.FC = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state: RootState) => state.drawer.isOpen);
    const closeDrawer = () => dispatch(setToggled(false));

    return (
        <Drawer
            transitionDuration={400}
            open={isOpen}
            onClose={closeDrawer}
            onClick={closeDrawer}
            onKeyDown={closeDrawer}
        >
            <Link to={'/'}>
                <img src="/images/logo.webp" alt="logo" style={{ width: 80, height: 80, marginBottom: 20 }} />
            </Link>
            <List>
                <Divider sx={{ borderColor: COLORS.lightGrey }} />
                {DRAWER_ROUTES.map((route) => (
                    <React.Fragment key={route.link}>
                        <Link to={route.link}>
                            <ListItem key={route.link}>{route.text}</ListItem>
                        </Link>
                        <Divider sx={{ borderColor: COLORS.lightGrey }} />
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
};

export default CustomDrawer;
